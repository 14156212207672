import React from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material';
import theme from '@consid/theme';
import '@fontsource/plus-jakarta-sans/400.css';
import Table from '../Table/Table';
import Header from '../Header';
import SearchTextField from '../SearchTextField';
import JobsContextProvider from '../../JobsContext';
import SideMenu from '../SideMenu';

export type Job = {
  applyUrl: string;
  companyId: string;
  companyName: string;
  companyUrl: string;
  description: string;
  expireAt: string;
  externalId: string;
  id: number;
  listedAt: string;
  location: string;
  numberOfApplicants: number;
  skills: string[];
  state: string;
  title: string;
  type: string;
  url: string;
  workPlace: string;
  isConsultingJob: boolean;
  companyIndustries: string[];
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <JobsContextProvider>
          <Header />
          <SideMenu />
          <div style={{ marginTop: '100px' }} className='App-content'>
            <div className='App-container'>
              <SearchTextField />
              <Table />
            </div>
          </div>
        </JobsContextProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
