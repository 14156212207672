import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Slider from './Slider';
import MultipleSelectChip from './MultipleSelectChip';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useJobsContext } from '../JobsContext';
import { useState } from 'react';

const drawerWidth = 240;

const SideMenu = () => {
  const {
    jobs,
    setSelectedSkills,
    setSelectedNumberOfApplicants,
    setSelectedLocations,
    setSelectedCompanies,
    setShowExpiredJobs,
    setSelectedDaysPublished,
    showExpiredJobs,
  } = useJobsContext();
  const skills = [...new Set(jobs?.flatMap((job) => job.skills).sort())];
  const locations = [...new Set(jobs?.flatMap((job) => job.location).sort())];
  const companies = [...new Set(jobs?.flatMap((job) => job.companyName).sort())];

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant='permanent'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        {/*<Toolbar />*/}
        <Box sx={{ overflow: 'auto', marginTop: '100px' }}>
          <List>
            <ListItem>
              <Slider
                title='Applicants'
                onChange={(applications: number) => setSelectedNumberOfApplicants(applications)}
              />
            </ListItem>
            <ListItem>
              <Slider title='Days published' onChange={(days: number) => setSelectedDaysPublished(days)} />
            </ListItem>
            <ListItem>
              {skills && (
                <MultipleSelectChip
                  title={'Skills'}
                  options={skills}
                  onClick={(skills: string[]) => setSelectedSkills(skills)}
                />
              )}
            </ListItem>
            <ListItem>
              {locations && (
                <MultipleSelectChip
                  title={'Locations'}
                  options={locations}
                  onClick={(locations: string[]) => setSelectedLocations(locations)}
                />
              )}
            </ListItem>
            <ListItem>
              {companies && (
                <MultipleSelectChip
                  title={'companyName'}
                  options={companies}
                  onClick={(companies: string[]) => setSelectedCompanies(companies)}
                />
              )}
            </ListItem>
            <ListItem>
              {
                <FormControlLabel
                  label='Visa utgångna'
                  control={
                    <Checkbox
                      checked={showExpiredJobs}
                      onChange={() => setShowExpiredJobs(!showExpiredJobs)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              }
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Drawer>
    </Box>
  );
};

export default SideMenu;
