import React, { useState } from 'react';
import './Table.css';
import {
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format, compareAsc, differenceInDays } from 'date-fns';
import { Job } from '../App/App';
import TableCell from '../TableCell/TableCell';
import EnhancedTableHead from '../TableHead/EnhancedTableHead';
import linkedin from '../../svg/linkedin.svg';
import { daysSinceToday, today } from '../../utils/utils';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useJobsContext } from '../../JobsContext';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: Job[] | undefined, comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis ? stabilizedThis.map((el) => el[0]) : [];
}

const Table = () => {
  const { jobs, filteredJobs } = useJobsContext();
  const loading = !jobs;
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('listedAt');
  const [dense, setDense] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredJobs, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredJobs]
  );

  return (
    <TableContainer component={Paper}>
      <MuiTable
        sx={{ minWidth: 650, marginTop: '100px', marginLeft: '0' }}
        aria-label='collapsible table'
        size={dense ? 'small' : 'medium'}
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={filteredJobs?.length}
        />
        <TableBody>{visibleRows?.map((job) => <Row key={job.id} job={job} today={today} />)}</TableBody>
      </MuiTable>
      {loading && (
        <div className='Table-loader'>
          <CircularProgress />
        </div>
      )}
      {!loading && filteredJobs?.length === 0 && <div className='Table-loader'>Inga jobb hittades...</div>}
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label='Dense padding' />
      <TablePagination
        rowsPerPageOptions={[50, 100, 200]}
        component='div'
        count={filteredJobs?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

function Row(props: { job: ReturnType<any>; today: string }) {
  const { job, today } = props;
  const listedAt = format(job.listedAt, 'yyyy-MM-dd');
  const daysPublished = daysSinceToday(job.listedAt);
  const newJob = compareAsc(listedAt, today) === 0;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell bold={newJob}>{job.title}</TableCell>
        <TableCell>{job.companyName}</TableCell>
        <TableCell>{job.companyIndustries.join(', ')}</TableCell>
        <TableCell>{job.location}</TableCell>
        <TableCell>{job.workPlace}</TableCell>
        <TableCell>{job.isConsultingJob ? 'Yes' : 'No'}</TableCell>
        <TableCell>{job.numberOfApplicants}</TableCell>
        <TableCell>{daysPublished}</TableCell>
        <TableCell style={{ whiteSpace: 'nowrap' }}>{format(job.listedAt, 'yyyy-MM-dd')}</TableCell>
        <TableCell style={{ whiteSpace: 'nowrap' }}>{format(job.expireAt, 'yyyy-MM-dd')}</TableCell>
        <TableCell align='right'>
          <a href={job.url} target='_blank' rel='noreferrer'>
            <img src={linkedin} width={26} height={26} alt='linkedin' />
          </a>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <MuiTable size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Skills</TableCell>
                    {/*<TableCell>Description</TableCell>*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={job.id}>
                    <TableCell>
                      <List dense={true} sx={{ listStyleType: 'disc' }}>
                        {job.skills.map((skill: string) => (
                          <ListItem key={skill} sx={{ display: 'list-item' }}>
                            {skill}
                          </ListItem>
                        ))}
                      </List>
                    </TableCell>
                    {/*<TableCell>{job.description}</TableCell>*/}
                  </TableRow>
                </TableBody>
              </MuiTable>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Table;
