import React from 'react';
import { Order } from '../Table/Table';
import Box from '@mui/material/Box';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

interface Props {
  // numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount?: number;
}

interface HeadCell {
  // disablePadding: boolean;
  // id: keyof Data;
  id: string;
  label: string;
  // numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'title',
    label: 'title',
  },
  {
    id: 'companyName',
    label: 'companyName',
  },
  {
    id: 'companyIndustries',
    label: 'companyIndustries',
  },
  {
    id: 'location',
    label: 'location',
  },
  {
    id: 'workPlace',
    label: 'workPlace',
  },
  {
    id: 'isConsultingJob',
    label: 'isConsultingJob',
  },
  {
    id: 'numberOfApplicants',
    label: 'numberOfApplicants',
  },
  {
    id: 'daysPublished',
    label: 'daysPublished',
  },
  {
    id: 'listedAt',
    label: 'listedAt',
  },
  {
    id: 'expireAt',
    label: 'expireAt',
  },
  {
    id: 'url',
    label: 'url',
  },
];

function EnhancedTableHead(props: Props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ fontSize: '1rem' }} />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ fontSize: '1rem' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
