import { FormControl, TextField } from '@mui/material';
import * as React from 'react';
import { useJobsContext } from '../JobsContext';

const SearchTextField = () => {
  const { setSearchToken } = useJobsContext();
  return (
    <FormControl
      sx={{
        display: 'flex',
        width: '1000px',
        alignSelf: 'center',
        margin: 'auto',
        marginTop: '100px',
      }}
    >
      <TextField
        id='outlined-search'
        label='Search title...'
        type='search'
        variant='outlined'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchToken(event.target.value);
        }}
      />
    </FormControl>
  );
};

export default SearchTextField;
