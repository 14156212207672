import * as React from 'react';
import { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';

interface Props {
  title: string;
  options: string[];
  onClick: any;
}

const MultipleSelectChip: FC<Props> = ({ title, options, onClick }) => {
  const handleChange = (value: string[]) => onClick(value);

  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        disableCloseOnSelect={true}
        size='small'
        multiple
        limitTags={2}
        renderInput={(params) => <TextField {...params} label={title} placeholder='more stuff' />}
        options={options}
        getOptionLabel={(option) => option}
        onChange={(event, newValue) => handleChange(newValue)}
      />
    </div>
  );
};

export default MultipleSelectChip;
