import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Job } from './components/App/App';
import { compareAsc, format } from 'date-fns';
import { daysSinceToday, today } from './utils/utils';

const getJobs = async (url: string): Promise<Job[]> => {
  const response = await fetch(url, {
    method: 'GET',
  });
  if (!response.ok) {
    throw new Error("Can't receive jobs");
  }
  return response.json() as unknown as Job[];
};

type ContextType = {
  jobs?: Job[];
  numberOfNewJobs?: number;
  setJobs: (data: any) => void;
  filteredJobs?: Job[];
  handleChange: (data: any) => void;
  setSearchToken: (data: any) => void;
  setSelectedSkills: (data: any) => void;
  setSelectedLocations: (data: any) => void;
  setSelectedCompanies: (data: any) => void;
  setShowExpiredJobs: (data: any) => void;
  setSelectedNumberOfApplicants: (data: any) => void;
  showExpiredJobs: boolean;
  setSelectedDaysPublished: (data: any) => void;
};

type ContextProviderType = {
  children: React.ReactNode;
};

const JobsContext = createContext<ContextType>({
  jobs: undefined,
  filteredJobs: undefined,
  numberOfNewJobs: undefined,
  setJobs: () => null,
  handleChange: () => null,
  setSearchToken: () => null,
  setSelectedSkills: () => null,
  setSelectedLocations: () => null,
  setSelectedCompanies: () => null,
  setShowExpiredJobs: () => null,
  setSelectedNumberOfApplicants: () => null,
  setSelectedDaysPublished: () => null,
  showExpiredJobs: true,
});

const JobsContextProvider = ({ children }: ContextProviderType) => {
  const [jobs, setJobs] = useState<Job[] | undefined>(undefined);
  const [filteredJobs, setFilteredJobs] = useState<Job[]>();
  const [selectedNumberOfApplicants, setSelectedNumberOfApplicants] = useState<number>(1000);
  const [selectedDaysPublished, setSelectedDaysPublished] = useState<number | undefined>(undefined);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [showExpiredJobs, setShowExpiredJobs] = useState<boolean>(true);
  const [searchToken, setSearchToken] = useState<string>();
  const numberOfNewJobs = useMemo(
    () => jobs?.filter((job) => compareAsc(format(job.listedAt, 'yyyy-MM-dd'), today) === 0).length,
    [jobs]
  );

  useEffect(() => {
    const url = 'https://spiderman-api.graycoast-f69dced6.swedencentral.azurecontainerapps.io/api/job';
    getJobs(url).then((response) => {
      setJobs(response);
      setFilteredJobs(response);
    });
  }, []);

  useEffect(() => {
    handleChange();
  }, [
    selectedNumberOfApplicants,
    selectedSkills,
    selectedLocations,
    selectedCompanies,
    jobs,
    searchToken,
    showExpiredJobs,
    selectedDaysPublished,
  ]);

  const filterBySearch = (job: Job) =>
    !searchToken ||
    searchToken
      .toLowerCase()
      .split(' ')
      .every((v) => job.title.toLowerCase().includes(v));

  const filterBySkills = (skills: string[]) =>
    selectedSkills.length === 0 || !!skills.find((skill: string) => selectedSkills.includes(skill));

  const filterByNumberOfApplicants = (job: Job) =>
    !selectedNumberOfApplicants || job.numberOfApplicants <= selectedNumberOfApplicants;

  const filterByNumberOfDaysPublished = (job: Job) =>
    !selectedDaysPublished || daysSinceToday(job.listedAt) <= selectedDaysPublished;

  const filterByLocation = (job: Job) => selectedLocations.length === 0 || selectedLocations.includes(job.location);

  const filterByCompanyName = (job: Job) =>
    selectedCompanies.length === 0 || selectedCompanies.includes(job.companyName);

  const filterByExpired = (job: Job) => showExpiredJobs || compareAsc(format(job.expireAt, 'yyyy-MM-dd'), today) !== -1;

  const hasJobsBasedOnFilters = () =>
    jobs?.filter(
      (job) =>
        filterBySearch(job) &&
        filterByExpired(job) &&
        filterBySkills(job.skills) &&
        filterByNumberOfApplicants(job) &&
        filterByLocation(job) &&
        filterByCompanyName(job) &&
        filterByNumberOfDaysPublished(job)
    );

  const handleChange = () => {
    setFilteredJobs(hasJobsBasedOnFilters());
  };

  const value = {
    numberOfNewJobs,
    jobs,
    setJobs,
    handleChange,
    setSearchToken,
    setSelectedSkills,
    setSelectedLocations,
    setSelectedCompanies,
    setShowExpiredJobs,
    setSelectedNumberOfApplicants,
    setSelectedDaysPublished,
    filteredJobs,
    showExpiredJobs,
  };

  return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>;
};

export default JobsContextProvider;

export const useJobsContext = () => {
  const context = useContext(JobsContext);
  if (!context) {
    throw new Error('useJobsContext must be used within a ContextProvider');
  }
  return context;
};
