import * as React from 'react';
import Box from '@mui/material/Box';
import MuiSlider from '@mui/material/Slider';
import { FC } from 'react';

interface Props {
  onChange: any;
  title: string;
}

const Slider: FC<Props> = ({ onChange, title }) => {
  const [value, setValue] = React.useState(50);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
    onChange(newValue);
  };

  return (
    <Box sx={{ width: 300 }}>
      <span>{title}</span>
      <MuiSlider
        size='small'
        defaultValue={70}
        aria-label='Always visible'
        valueLabelDisplay='on'
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
};

export default Slider;
