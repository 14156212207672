import React, { FC } from 'react';
import { TableCell as MuiTableCell } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  align?: any;
  bold?: boolean;
  style?: any;
  colSpan?: number;
}

const TableCell: FC<Props> = ({ children, align, bold, style, colSpan }) => {
  return (
    <MuiTableCell align={align} colSpan={colSpan} className='TableCell' style={{ fontSize: '1rem', ...(bold && { fontWeight: 'bold' }), ...(style && style) }}>
      {children}
    </MuiTableCell>
  );
};

export default TableCell;
